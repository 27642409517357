<template>
    <v-container fluid>
        <div class="pl-4 pr-0 pt-2 d-flex">
            <div class="pr-0 pt-4 left_filter">
                <setting-menu @filter_show="handleFilterShow"></setting-menu>
            </div>
            <div class="div_body pl-3 pr-0 mx-0">
                <v-form @submit.prevent="getLocationEvents">
                    <v-btn v-show="$vuetify.breakpoint.xsOnly" @click.stop="addLocationEvent()"
                           color="indigo" dark fab fixed top right class="v-btn--add-form-top">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                    <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                        <v-card flat class="background_color_transparent">
                            <v-card-text class="py-0">
                                <v-row class="pt-5">
                                    <v-col cols="12" sm="8" class="title_menu mt-1">
                                        {{  $t('location_event') }}
                                    </v-col>
                                    <v-col v-if="!$vuetify.breakpoint.xsOnly" v-show="!filter_show" cols="12" sm="4" class="text-right">
                                        <v-btn :to="{name: 'location_event.create'}" class="infinity_button" color="primary">
                                            {{$t('create') }}
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <v-row v-if="filter_show">
                                    <v-col class="py-0" cols="12" >
                                        <div class="input_label">{{$t('user_name')}}</div>
                                        <ValidationProvider ref="admin" rules="min:1"
                                                            v-slot="{ errors, valid }">
                                            <v-autocomplete v-model="admin" :items="adminItems"
                                                            :error-messages="errors" :error="!valid"
                                                            :search-input.sync="adminSearching"
                                                            item-text="name" item-value="id"
                                                            :loading="loadingAdmins"
                                                            :no-data-text="adminSearching ? $t('nothing_found_by',{'search': adminSearching}) : $t('nothing_found_name')"
                                                            :label="$t('user_name')"
                                                            @click:clear="adminItems=[]"
                                                            @click="clearAdmins"
                                                            autocomplete="off"
                                                            :disabled="loading"
                                                            outlined
                                                            solo
                                                            flat
                                                            dense
                                                            hide-details
                                                            :height="$vuetify.breakpoint.xsOnly ? 45 : 31"
                                                            color="primary"
                                                            class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                            clearable>
                                                <template v-slot:item="{ attrs, item, parent, selected }">
                                                <span class="search-list">
                                                    <span v-html="item.name"></span>
                                                    <span class="ml-2 hidden-is-empty"
                                                          v-text="item.phone"></span>
                                                </span>
                                                </template>
                                                <template v-slot:selection="{ attrs, item, parent, selected }">
                                                <span class="search-list">
                                                    <span v-html="item.name"></span>
                                                    <span class="ml-2 hidden-is-empty"
                                                          v-text="item.address_legal"></span>
                                                </span>
                                                </template>
                                            </v-autocomplete>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12">
                                        <ValidationProvider ref="location_event" rules=""
                                                            v-slot="{ errors, valid }">
                                            <div class="input_label">{{$t('location_event')}}</div>
                                            <v-select v-model="location_event"
                                                      :items="location_eventItems" :error-messages="errors"
                                                      :error="!valid"
                                                       item-text="name" item-value="id"
                                                      :label="$t('location_event')"
                                                      outlined
                                                      solo
                                                      flat
                                                      dense
                                                      :disabled="loading"
                                                      :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                      color="primary"
                                                      class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                      clearable></v-select>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-card-actions v-if="filter_show" class="px-4 py-4">
                                <v-row>
                                    <v-col cols="12" sm="12" md="12"
                                           class="d-flex justify-center justify-sm-end align-self-center">
                                        <v-btn type="submit" :disabled="invalid || loading" :block="$vuetify.breakpoint.xsOnly"
                                               color="primary">{{ $t('search') }}
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-actions>
                        </v-card>
                    </ValidationObserver>
                </v-form>
                <v-card flat class="background_color_transparent">
                    <v-card-text class="pt-7">
                        <v-data-table item-key="id" ref="table" :headers="headers" :items="location_eventItems"
                                      :options.sync="options" disable-pagination
                                      :sort-by.sync="sortBy" :sort-desc.sync="sortDir"
                                      :loading="loading" :locale="lang" :loading-text="$t('loading_please_wait')"
                                       hide-default-footer
                                      :hide-default-header="(!$vuetify.breakpoint.xsOnly)"
                                      class="sortable-table elevation-0"
                                      :item-class="rowClass">
                            <template v-slot:item.sort>
                                <v-btn icon class="handle">
                                    <v-icon>mdi-drag-horizontal</v-icon>
                                </v-btn>
                            </template>
                            <template v-slot:header="{ props: { headers } }" v-if="(!$vuetify.breakpoint.xsOnly)">
                                <thead>
                                <tr class="rowHeadClass Table3Sort">
                                    <th v-for="h in headers" :key="h.value" class="table_header">
                                        <span>{{h.text}}</span>
                                    </th>
                                </tr>
                                </thead>
                            </template>

                            <template v-slot:item.name="{ item }">
                                <div :class="item.deleted ? 'red--text' : ''">
                                    <span  class="cursor-pointer font_weight_600" @click="editLocationEvent(item)" >
                                        {{  item.name }}
                                        {{ item.deleted ? ' (' + $t('deleted') + ')' : '' }}
                                    </span>
                                    <v-icon v-if="!item.deleted" @click="deleteLocationEvent(item)" small>mdi-delete-outline</v-icon>
                                </div>
                            </template>


                        </v-data-table>
                    </v-card-text>
                </v-card>
            </div>
        </div>
    </v-container>
</template>
<script>

import {ValidationObserver, ValidationProvider} from 'vee-validate'
import Sortable from 'sortablejs'
import {mapGetters} from "vuex"
import SettingMenu from "../components/SettingMenu";
import debounce from "lodash/debounce";

export default {
    name: 'LocationEvents',
    components: {
        ValidationProvider,
        ValidationObserver,
        SettingMenu
    },
    inject: ['forceRerender'],
    data() {
        return {
            heading: this.$t('location_event_creation'),
            progress: 0,
            language: null,
            loading: false,
            sortBy: "sort",
            sortDir: false,
            options: {},
            location_eventItems: [],
            errors: {},
            id: null,
            name: null,
            location_event: null,
            headers: [
                {
                    text: "",
                    align: "left",
                    sortable: false,
                    value: "sort",
                },
                {
                    text: this.$t('name'),
                    align: "left",
                    sortable: false,
                    value: "name"
                },
                {
                    text: this.$t('manager'),
                    align: "left",
                    sortable: false,
                    value: "admin.name"
                }
            ],
            filter_show: false,
            admin: null,
            adminItems: [],
            adminSearching: null,
            loadingAdmins: false,
        }
    },
    computed: {
        ...mapGetters(['listLanguages', 'lang']),
        languages() {
            return this.listLanguages
        },
    },
    mounted() {
        this.language = this.languages[this.tab]
        this.checkCreate()
        let table = this.$refs.table.$el.querySelector("tbody")
        Sortable.create(table, {
            handle: ".handle",
            onEnd: this.dragReorder
        })

    },
    watch: {
        options: {
            handler() {
                this.getLocationEvents()
            },
            deep: false
        },
        adminSearching: debounce(function (val) {
            if (val && !this.admin) {
                this.getAdmins(val)
            }
        }, 500),
    },
    methods: {
        handleFilterShow(action){
            this.filter_show = action;
        },
        rowClass(){
            return (!this.$vuetify.breakpoint.xsOnly) ? 'rowClass Table3Sort' : 'rowMobiClass';
        },
        setLanguage(val) {
            this.language = this.languages[val]
        },
        checkCreate() {
             this.heading = this.$t('location_event_creation')

        },
        dragReorder({newIndex, oldIndex}) {
            const rowSelected = this.location_eventItems.splice(oldIndex, 1)[0]
            this.location_eventItems.splice(newIndex, 0, rowSelected)
            this.saveSortLocationEvent()
        },
        async saveSortLocationEvent() {
            var _this = this
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.location_eventItems && this.location_eventItems.length > 0) {
                for (let i in this.location_eventItems) {
                    if (this.location_eventItems[i].id !== 'undefined' && this.location_eventItems[i].id > 0) {
                        formData.append(`sort[${i}]`, this.location_eventItems[i].id)
                    }
                }
            }
            await this.$http
                .post(`admin/location_event/sort`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('location_event_sorting_updated'))
                    //this.forceRerender()
                })
                .catch(err => {
                    this.$toastr.error(this.$t('location_event_sorting_not_updated'))
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },
        async getLocationEvents() {
            this.loading = true
            const {
                sortBy,
                sortDesc,
            } = this.options
            let params = {}

            if (sortBy[0] !== undefined) {
                params.sortBy = sortBy[0]
            }
            if (sortDesc[0] !== undefined) {
                params.sortDir = sortDesc[0] ? 'desc' : 'asc'
            }
            if (this.location_event) {
                params.name = this.location_event
            }
            if (this.admin) {
                params.admin_id = this.admin
            }

            await this.$http
                .get("admin/location_event", {
                    params: params,
                })
                .then(res => {
                    this.location_eventItems = res.body.data
                })
                .catch(err => {
                    this.location_eventItems = []
                })
                .finally(end => {
                    this.loading = false
                })
        },
        async deleteLocationEvent(item) {
            if (confirm(this.$t('delete_location_event'))) {
                this.loading = true
                await this.$http
                    .delete(`admin/location_event/${item.id}`)
                    .then(res => {
                        this.getLocationEvents()
                    })
                    .catch(err => {
                        this.LocationEventStatusItems = []
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        },
        addLocationEvent() {
            this.$router.push({
                name: 'location_event.create',
            })
        },
        editLocationEvent(item) {
            this.$router.push({
                name: 'location_event.edit',
                params: {
                    id: item.id
                }
            })
        },
        async getAdmins(str) {
            if (str) {
                this.loadingAdmins = true
                let params = {}
                params.filter = 'search'
                if (str !== 'undefined') {
                    params.admin = str
                }
                await this.$http
                    .get("admin/admin", {
                        params: params,
                    })
                    .then(res => {
                        this.adminItems = res.body.data
                    })
                    .catch(err => {
                        this.adminItems = []
                    })
                    .finally(end => {
                        this.loadingAdmins = false
                    })
            }
        },
        clearAdmins() {
            if (!this.admin) {
                this.adminItems = []
            }
        },
    }
}
</script>
